import React, { useEffect } from "react";
import Header from "../components/header/Header";
import { useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddPdf = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const location = useLocation();
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [date, setdate] = useState(new Date());
  const [pdf, setpdf] = useState(null);

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("name", name);
    formData.append("date", date);
    formData.append("description", description);

    try {
      const response = await axios.post("/pdf/add", formData, {
        headers: {
          authorization: `Bearer ${auth?.token}`,
        },
      });

      if (!response?.data?.success) {
        toast.error("Please Select Any File");
      }

      response.data?.success && toast.success("File Uploaded Successfully");

      response.data?.success & navigate("/pdf/list");
      // setrefresh(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Header page="AddPdf" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Upload PDF</h3>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="card-body">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Name :</label>
                    <input
                      type="name"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Description :</label>
                    <textarea
                      type="name"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter File Description"
                      value={description}
                      onChange={(e) => {
                        setdescription(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Date :</label>
                    <br></br>
                    <DatePicker
                      selected={date}
                      className="form-control input-sm"
                      onChange={(date) => setdate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText=""
                    />
                    <br></br>
                  </div>
                  <div className="form-group">
                    <label>Upload PDF :</label>
                    <input
                      type="file"
                      className="form-control"
                      id="product-image"
                      rows={8}
                      required
                      onChange={(e) => setpdf(e.target.files[0])}
                    />
                    <div className="invalid-feedback">File is Required!</div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Submit
                  </button>
                </div>
                <StatusToast />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddPdf;
