import Home from "../pages/Home";
import Login from "../pages/Login";
import PdfList from "../pages/PdfList";
import AddPdf from "../pages/AddPdf";
const path = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/pdf/list",
    component: PdfList,
    roles: ["admin", "user"],
  },
  {
    path: "/pdf/add",
    component: AddPdf,
    roles: ["admin"],
  },
  {
    path: "/login",
    component: Login,
    public: true,
  },
];

export default path;
