const menu = [
  {
    name: "Entry",
    icon: "fa-tachometer-alt",
    roles: ["admin"],
    children: [
      {
        name: "Add PDF",
        icon: "fa-tachometer-alt",
        path: "/pdf/add",
      },
      {
        name: "PDF List",
        icon: "fa-tachometer-alt",
        path: "/pdf/list",
      },
    ],
  },
];
export default menu;
